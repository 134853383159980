import { Suspense, memo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import Keyboard from '@mui/icons-material/Keyboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import DBTPreloadSettingsModal from 'src/components/modals/DBTPreloadSettingsModal';
import ShortcutsModal from 'src/components/modals/ShortcutsModal';
import ShortcutAlert from 'src/pages/workspace/ShortcutAlert';
import Toolbar from 'src/pages/workspace/footer/Toolbar';
import { projectState } from 'src/states/project';

function Footer(): JSX.Element {
  const isDBTProject = useRecoilValue(projectState.isDBT);
  const [
    DBTPreloadSettingsModalVisibility,
    setDBTPreloadSettingsModalVisibility,
  ] = useState(false);
  const [shortcutsModalVisibility, setShortcutsModalVisibility] =
    useState(false);

  const toggleDBTPreloadSettingsModal = () =>
    setDBTPreloadSettingsModalVisibility(prev => !prev);
  const toggleShortcutsModal = () => setShortcutsModalVisibility(prev => !prev);

  return (
    <Container>
      <div className="left">
        {isDBTProject && (
          <>
            <MenuItem onClick={toggleDBTPreloadSettingsModal}>
              <SettingsIcon />
            </MenuItem>
            <DBTPreloadSettingsModal
              visibility={DBTPreloadSettingsModalVisibility}
              onClose={toggleDBTPreloadSettingsModal}
            />
          </>
        )}

        <MenuItem
          onClick={toggleShortcutsModal}
          data-test-id="shortcuts-button"
        >
          <Keyboard />
        </MenuItem>
        <ShortcutAlert />
        <ShortcutsModal
          visibility={shortcutsModalVisibility}
          onClose={toggleShortcutsModal}
        />
      </div>
      <div className="center">
        <Suspense
          fallback={
            <CircularProgress
              size={18}
              color="inherit"
              data-test-id="toolbar-loading-indicator"
            />
          }
        >
          <Toolbar />
        </Suspense>
      </div>
      <div className="right">
        {`Powered by Lunit Inc. ${new Date().getFullYear()}`}
      </div>
    </Container>
  );
}

export default memo(Footer);

export const Container = styled('footer')`
  background-color: var(--ctl-background-color);
  display: flex;
  height: var(--ctl-footer-height);
  align-items: center;
  justify-content: space-between;

  > .left {
    flex: 1;
    display: flex;
    height: 100%;
    gap: 0.5rem;
  }

  > .center {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .right {
    flex: 1;
    text-align: right;
    padding-right: 16px;
  }
`;
