import {
  useLayoutEffect,
  useState,
  useCallback,
  useEffect,
  useMemo,
  WheelEventHandler,
} from 'react';

import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import FrameNavigator from 'src/components/FrameNavigator';
import LabelPanel from 'src/components/LabelPanel';
import AssetPanel from 'src/components/LabelPanel/Asset/AssetPanel';
import { withErrorShake } from 'src/components/withErrorShake';
import useConfirmFinding from 'src/hooks/tasks/useConfirmFinding';
import useRemoveFinding from 'src/hooks/tasks/useRemoveFinding';
import useSetFindingIndex from 'src/hooks/tasks/useSetFindingIndex';
import useSetFindingLabel from 'src/hooks/tasks/useSetFindingLabel';
import useSetShowDBT3DImages from 'src/hooks/tasks/useSetShowDBT3DImages';
import useAlert from 'src/hooks/useAlert';
import useDraggablePanelPosition from 'src/hooks/useDraggablePanelPosition';
import useImagePaths from 'src/hooks/useImagePaths';
import { useValidateFinding } from 'src/hooks/useValidateFinding';
import { errorFindingIdxState } from 'src/states/finding';
import issuesState from 'src/states/issues';
import { jobState } from 'src/states/job';
import { operationModeState } from 'src/states/operationMode';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';
import assetValidator from 'src/utils/AssetValidator';
import { ValidationFailure } from 'src/utils/AssetValidator/errors';
import FindingUtils from 'src/utils/finding';
import { isArray } from 'src/utils/typeHelper';

const FindingLabelPanel = (): JSX.Element | null => {
  const findingIndex = useRecoilValue(taskState.findingIndex);
  const currentFinding = useRecoilValue(taskState.currentFinding);
  const isMultiFramePolygon = useRecoilValue(taskState.isMultiFramePolygon);
  const findingsInCurrentGroup = useRecoilValue(
    taskState.findingsInCurrentGroup
  );
  const currentFindingAssets = useRecoilValue(taskState.currentFindingAssets);

  const [errorFindingIdx, setErrorFindingIdx] =
    useRecoilState(errorFindingIdxState);
  const setFindings = useSetRecoilState(taskState.localFindings);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const isCurrentModeEditable = useRecoilValue(
    operationModeState.isCurrentModeEditable
  );
  const setActivatedId = useSetRecoilState(issuesState.activatedId);
  const setShowDBT3D = useSetShowDBT3DImages();

  const confirmFinding = useConfirmFinding();
  const setFindingIndex = useSetFindingIndex();
  const removeFinding = useRemoveFinding();
  const handleChangeFindingLabel = useSetFindingLabel();

  const { open: openAlert } = useAlert();
  const validateFinding = useValidateFinding();

  const { containerRef, offset, setOffset, setOffsetWhenRefElementIsVisible } =
    useDraggablePanelPosition();
  const [lastActiveThumb, setLastActiveThumb] = useState<
    'startFrame' | 'endFrame'
  >('startFrame');

  const job = useRecoilValue(jobState.current);

  const currentView = useMemo(
    () => currentFinding?.image || '',
    [currentFinding?.image]
  );

  const setIsAnnotating = useSetRecoilState(jobState.isAnnotating);
  const [currentFrame, setCurrentFrame] = useRecoilState(
    taskState.currentFrame(currentView)
  );
  const totalFrames = useImagePaths({ job, imageKey: currentView }).length;

  const frameRangeOfMultiFramePolygon = useMemo(
    () =>
      isMultiFramePolygon
        ? [currentFinding?.startFrame || 0, currentFinding?.endFrame || 0]
        : undefined,
    [currentFinding?.endFrame, currentFinding?.startFrame, isMultiFramePolygon]
  );

  const totalFramesSelected = useMemo(
    () =>
      isMultiFramePolygon
        ? (currentFinding?.endFrame || 0) -
          (currentFinding?.startFrame || 0) +
          1
        : 0,
    [currentFinding?.endFrame, currentFinding?.startFrame, isMultiFramePolygon]
  );

  const onChangeSelectedFrames = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    if (!isArray(value)) return;
    setLastActiveThumb(activeThumb === 0 ? 'startFrame' : 'endFrame');
    setCurrentFrame(value[activeThumb] || 0);

    const [newStartFrame, newEndFrame] = value;
    if (newStartFrame === undefined || newEndFrame === undefined) {
      return;
    }

    setFindings(prev => {
      const index = prev.findIndex(a => a.index === findingIndex);
      if (index < 0 || !currentFinding) return prev;
      const updatedCurrentFinding = {
        ...currentFinding,
        startFrame: newStartFrame,
        endFrame: newEndFrame,
        confirmed: false,
      };

      return prev.map((prevFinding, i) => {
        if (i === index) return updatedCurrentFinding;
        return prevFinding;
      });
    });
    setIsAnnotating(true);
  };

  const handleMouseWheelToSetCurrentFrame: WheelEventHandler<
    HTMLUListElement
  > = ({ deltaY }) => {
    let newCurrentFrame = 0;
    setFindings(prev => {
      const index = prev.findIndex(a => a.index === findingIndex);
      if (
        index < 0 ||
        !currentFinding ||
        currentFinding.endFrame === undefined ||
        currentFinding.startFrame === undefined
      )
        return prev;

      let frameLimits: number[] = [];

      if (lastActiveThumb === 'startFrame') {
        frameLimits = [0, currentFinding.endFrame];
      } else if (lastActiveThumb === 'endFrame') {
        frameLimits = [currentFinding.startFrame, totalFrames - 1];
      }
      newCurrentFrame = FindingUtils.newCurrentFrameFromMouseWheel(
        deltaY,
        currentFrame,
        frameLimits
      );

      const updatedCurrentFinding = {
        ...currentFinding,
        [lastActiveThumb]: newCurrentFrame,
        confirmed: false,
      };
      return prev.map((prevFinding, i) => {
        if (i === index) return updatedCurrentFinding;
        return prevFinding;
      });
    });
    setCurrentFrame(newCurrentFrame);
  };

  const isPrimaryFrameOutOfRange = useCallback(() => {
    if (!isMultiFramePolygon) return false;
    const { startFrame, endFrame, primaryFrame } = currentFinding ?? {};

    if (
      FindingUtils.isPrimaryFrameOutOfRange(primaryFrame, startFrame, endFrame)
    ) {
      openAlert({
        message: `Error with finding #${findingIndex}. Please delete it and try again.`,
        type: 'error',
      });
      return true;
    }
    return false;
  }, [currentFinding, findingIndex, isMultiFramePolygon, openAlert]);

  const isFindingImmutable =
    isConfirmedProject || !isCurrentModeEditable || currentFinding?.viewOnly;

  const isConfirmDisabled = useMemo(
    () => isFindingImmutable || isPrimaryFrameOutOfRange(),
    [isFindingImmutable, isPrimaryFrameOutOfRange]
  );

  /**
   * use useLayoutEffect for fast null assertion.
   * If use useEffect, contents will be change first before panel disappear.
   */
  useLayoutEffect(() => {
    if (!findingIndex) {
      setOffset(undefined);
      return;
    }

    setOffsetWhenRefElementIsVisible(findingIndex);
  }, [findingIndex, setOffset, setOffsetWhenRefElementIsVisible]);

  /**
   * If `currentFinding` is `multiFramePolygon`
   *   1. ensure it's visible
   *   2. set the `lastActiveThumb` as `startThumb`
   * TODO: Refactor if possible to avoid eslint-disable comment.
   * This logic should only run ONCE when a new `currentFinding`
   * is created or selected (click svg, click FindingItem, keyboard shortcut)
   *  */
  useLayoutEffect(() => {
    if (!currentFinding || findingIndex === undefined) return;
    setActivatedId(undefined);
    if (isMultiFramePolygon) {
      setShowDBT3D(true);
      const { startFrame, endFrame } = currentFinding;
      if (startFrame === undefined || endFrame === undefined) return;
      if (currentFrame < startFrame || currentFrame > endFrame) {
        setCurrentFrame(startFrame);
      }
      setLastActiveThumb('startFrame');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findingIndex]);

  const handleClose = useCallback(() => {
    setFindingIndex(undefined);
  }, [setFindingIndex]);

  useEffect(() => {
    const closeOnShortcut = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keyup', closeOnShortcut);
    return () => window.removeEventListener('keyup', closeOnShortcut);
  }, [handleClose]);

  const handleDragStop: DraggableEventHandler = (event, data) => {
    setOffset({ x: data.x, y: data.y });
  };

  const isGroupedFinding = useMemo(() => {
    if (!currentFinding) return false;
    return findingsInCurrentGroup.length > 1;
  }, [currentFinding, findingsInCurrentGroup.length]);

  const handleConfirm = () => {
    if (findingIndex === undefined || currentFinding === undefined) {
      return;
    }

    try {
      // Check claim assetValidation
      const currentLabels = currentFinding.labels;
      if (!currentLabels) {
        return;
      }

      assetValidator.validate(currentLabels, findingIndex);

      // Check legacy finding validation
      validateFinding(currentLabels)(currentFinding);

      // On validated, sync currentFinding's labels to ALL findings in the same groupe
      setFindings(prev => FindingUtils.syncedFindings(currentFinding, prev));
      handleClose();
      confirmFinding(findingIndex);

      const successMessage = isGroupedFinding
        ? `Synced & Confirmed Finding #${findingIndex}'s labels to all findings in its group.`
        : `Finding #${findingIndex} is confirmed successfully.`;

      openAlert({
        message: successMessage,
        type: 'success',
      });
    } catch (error) {
      if (error instanceof ValidationFailure) {
        setFindingIndex(findingIndex, false);
        setErrorFindingIdx(findingIndex);
        openAlert({
          message: `${error.message}`,
          type: 'error',
        });
      }
    }
  };

  const handleDelete = () => {
    if (!findingIndex) {
      return;
    }
    removeFinding(findingIndex);
  };

  return findingIndex !== undefined ? (
    <Draggable
      position={offset}
      onStop={handleDragStop}
      nodeRef={containerRef}
      bounds="html"
      handle=".draggable-handle"
    >
      <Container
        ref={containerRef}
        style={{ visibility: offset ? 'visible' : 'hidden' }}
        data-test-id="finding-label-panel"
      >
        <Handle className="draggable-handle">
          <div>Finding #{findingIndex}</div>
          <div>
            <Tooltip title="Confirm" placement="top">
              <span>
                <IconButton
                  aria-label="confirm"
                  size="small"
                  color="primary"
                  onClick={handleConfirm}
                  disabled={isConfirmDisabled}
                  data-test-id="confirm-finding-btn"
                >
                  <CheckIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Close" placement="top">
              <IconButton
                aria-label="close"
                size="small"
                color="error"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {!isFindingImmutable && (
              <Tooltip title="Delete" placement="top">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={handleDelete}
                >
                  <DeleteIcon
                    fontSize="inherit"
                    data-test-id="findingLabelPanel-delete-btn"
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Handle>

        <Content>
          {!!frameRangeOfMultiFramePolygon && (
            <AssetPanel
              style={{ margin: '0.7rem' }}
              assetText="SELECTED FRAMES"
              assetAllowEmpty={false}
              onWheel={handleMouseWheelToSetCurrentFrame}
            >
              <SelectedFramesContainer>
                <TotalSelectedFrames variant="caption">
                  Total selected frames: {totalFramesSelected}
                </TotalSelectedFrames>
                <FrameNavigator
                  totalFrames={totalFrames}
                  value={frameRangeOfMultiFramePolygon}
                  onChange={onChangeSelectedFrames}
                  fullWidth
                  disabled={isFindingImmutable}
                />
              </SelectedFramesContainer>
            </AssetPanel>
          )}
          {!!currentFinding?.labels && (
            <StyledContainer>
              <ShakableLabelPanel
                shake={errorFindingIdx === findingIndex}
                onShakeFinish={() => {
                  setErrorFindingIdx(undefined);
                }}
                assets={currentFindingAssets}
                labels={currentFinding.labels}
                onChangeLabel={handleChangeFindingLabel}
                isDraggable={true}
              />
            </StyledContainer>
          )}
        </Content>
      </Container>
    </Draggable>
  ) : null;
};

export default FindingLabelPanel;

const Container = styled('div')`
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 20rem;
  max-height: 50vh;
  background-color: var(--ctl-background-color-light);
  border: 1px solid var(--ctl-background-color-lightest);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.6);
`;

const Handle = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.4rem 0.25rem 0.9rem;
  color: var(--ctl-color);
  background-color: var(--ctl-background-color-dark);
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: move;
`;

const Content = styled('div')`
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SelectedFramesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 2px;
  gap: 16px;
`;

const TotalSelectedFrames = styled(Typography)`
  color: var(--ctl-color);
  line-height: 1;
`;

const ShakableLabelPanel = withErrorShake(LabelPanel);

const StyledContainer = styled('div')(
  ({ theme }) => `
  background-color: ${theme.palette.primary.dark};
`
);
