import { ReactElement, Suspense, memo, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import DBTViewer from 'src/components/viewers/dbt';
import LoadingViewer from 'src/components/viewers/loading';
import { MammographyViewer } from 'src/components/viewers/mammography';
import { XRayViewer } from 'src/components/viewers/xray';
import useAddFinding from 'src/hooks/tasks/useAddFinding';
import useSetFindingIndex from 'src/hooks/tasks/useSetFindingIndex';
import {
  ClientError,
  ClientErrorCode,
  ClientErrorUtil,
} from 'src/http/client-error';
import { ProjectType } from 'src/interfaces';
import initialJobIdState from 'src/states/initialJobId';
import { jobState } from 'src/states/job';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

export const IMAGE_PANEL_ID = 'image-panel';

function JobImagePanel(): ReactElement {
  const job = useRecoilValue(jobState.current);
  const projectType = useRecoilValue(projectState.type);
  const findings = useRecoilValue(taskState.findings);
  const findingIndex = useRecoilValue(taskState.findingIndex);

  // For rendering suspense fallback while loading initialJobIdState
  useRecoilValue(initialJobIdState.annotator);
  useRecoilValue(initialJobIdState.reviewer);

  const setFindingIndex = useSetFindingIndex();
  const addFinding = useAddFinding();

  const Viewer = useMemo(() => {
    if (job.reported) {
      throw new ClientError({
        code: ClientErrorCode.REPORTED_JOB,
        message: ClientErrorUtil.getMessage(ClientErrorCode.REPORTED_JOB),
      });
    }

    switch (projectType) {
      case ProjectType.MAMMOGRAPHY:
        return (
          <MammographyViewer
            findings={findings}
            findingIndex={findingIndex}
            setFindingIndex={setFindingIndex}
            addFinding={addFinding}
          />
        );
      case ProjectType.XRAY:
        return (
          <Suspense fallback={<LoadingViewer step={'IMAGE'} />}>
            <XRayViewer
              findings={findings}
              findingIndex={findingIndex}
              setFindingIndex={setFindingIndex}
              addFinding={addFinding}
            />
          </Suspense>
        );
      case ProjectType.TOMOSYNTHESIS:
        return (
          <Suspense fallback={<LoadingViewer step={'IMAGE'} />}>
            <DBTViewer
              findings={findings}
              findingIndex={findingIndex}
              setFindingIndex={setFindingIndex}
              addFinding={addFinding}
            />
          </Suspense>
        );
      default:
        throw new ClientError({
          code: ClientErrorCode.INVALID_PROJECT,
          message: `This project is invalid because type of it is not defined. Give project type : ${projectType}`,
        });
    }
  }, [
    addFinding,
    findingIndex,
    findings,
    job.reported,
    projectType,
    setFindingIndex,
  ]);

  return <Container id={IMAGE_PANEL_ID}>{Viewer}</Container>;
}

export default memo(JobImagePanel);

const Container = styled('div')`
  position: relative;
  width: 99%;
  height: 100%;
  display: flex;
  > :first-child {
    flex: 1;
  }
`;
