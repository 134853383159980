import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import { SideBar } from 'src/components/SideBar';
import AsideItemSkeleton from 'src/components/aside/AsideItemSkeleton';
import ViewerErrorFallback from 'src/components/viewers/ViewerErrorFallback';
import LoadingViewer from 'src/components/viewers/loading';
import { useLayoutState } from 'src/hooks/useLayoutState';
import { ProjectType } from 'src/interfaces';
import Aside from 'src/pages/workspace/aside';
import ControlPanel from 'src/pages/workspace/task/ControlPanel';
import FindingPanel from 'src/pages/workspace/task/FindingPanel';
import JobImagePanel from 'src/pages/workspace/task/JobImagePanel';
import ReportPanel from 'src/pages/workspace/task/ReportPanel';
import jobIdListState from 'src/states/jobIdList';
import { projectState } from 'src/states/project';
import { taskState } from 'src/states/task';

import CreateIssuePanel from './CreateIssuePanel';
import DecisionCategoryPanel from './DecisionCategoryPanel';
import DecisionLabelPanel from './DecisionLabelPanel';
import FindingLabelPanel from './FindingLabelPanel';
import InformationPanel from './InformationPanel';
import IssuePanel from './IssuePanel';
import IssueThreadPanel from './IssueThreadPanel';
import ProjectInitializeErrorFallback from './ProjectInitializeErrorFallback';
import TaskInitializer from './TaskInitializer';

export const TASK_CONTAINER_ID = 'task-container';

export function Task(): JSX.Element {
  const { id: currentProjectId } = useRecoilValue(projectState.current);
  const currentJobId = useRecoilValue(jobIdListState.currentJobId);
  const currentAssociateId = useRecoilValue(projectState.currentAssociateId);
  const project = useRecoilValue(projectState.current);
  const hasDecisionCategories = useRecoilValue(taskState.hasDecisionCategories);

  const { isAsideVisible, isPanelsVisible } = useLayoutState();

  if (project.claim.viewer.type === ProjectType.COMPUTED_TOMOGRAPHY) {
    throw new Error(
      `CCT modality projects are not supported,
      but the current loaded project "${project.id}" is in CCT modality.
      Please login again for using another modality.`
    );
  }

  return (
    <Container id={TASK_CONTAINER_ID}>
      <AsideWrapper $isAsideVisible={isAsideVisible}>
        <Aside />
      </AsideWrapper>

      <Main>
        <ErrorBoundary
          FallbackComponent={ProjectInitializeErrorFallback}
          /**
           * @see https://github.com/bvaughn/react-error-boundary/issues/74
           * Used key rather than resetKeys because, the change of resetKeys prop is not executing componentDidUpdate on ErrorBoundary.
           * There were several fixes about resetKeys not working on initial change like above, but it happens same in this case.
           */
          key={`${currentAssociateId}-${currentProjectId}`}
        >
          <Content>
            <ErrorBoundary
              FallbackComponent={ViewerErrorFallback}
              resetKeys={[currentJobId]}
            >
              <Suspense fallback={<LoadingViewer step={'JOB'} />}>
                <JobImagePanel />
              </Suspense>
            </ErrorBoundary>
          </Content>

          {isPanelsVisible && (
            <SideBar data-test-id="right-sidebar">
              <ErrorBoundary
                fallback={
                  <AsideItemSkeleton label="Information" isCollapsible />
                }
              >
                <Suspense
                  fallback={
                    <AsideItemSkeleton label="Information" isCollapsible />
                  }
                >
                  <InformationPanel />
                </Suspense>
              </ErrorBoundary>
              {project.claim.invisibleComponents?.includes(
                'Clinical Report'
              ) ? null : (
                <ErrorBoundary
                  fallback={<AsideItemSkeleton label="Report" isCollapsible />}
                >
                  <Suspense
                    fallback={
                      <AsideItemSkeleton label="Report" isCollapsible />
                    }
                  >
                    <ReportPanel />
                  </Suspense>
                </ErrorBoundary>
              )}
              <Suspense
                fallback={<AsideItemSkeleton label="Findings" isCollapsible />}
              >
                <ErrorBoundary fallback={<></>}>
                  <FindingPanel />
                </ErrorBoundary>
              </Suspense>
              <Suspense
                fallback={<AsideItemSkeleton label="Issues" isCollapsible />}
              >
                <IssuePanel />
              </Suspense>
              {hasDecisionCategories ? (
                <Suspense
                  fallback={
                    <AsideItemSkeleton label="Category" isCollapsible />
                  }
                >
                  <DecisionCategoryPanel />
                </Suspense>
              ) : (
                <Suspense
                  fallback={
                    <AsideItemSkeleton
                      label="Labels for Current Job"
                      isCollapsible
                    />
                  }
                >
                  <ErrorBoundary fallback={<></>}>
                    <DecisionLabelPanel />
                  </ErrorBoundary>
                </Suspense>
              )}
              <Suspense fallback={<AsideItemSkeleton label="Control" />}>
                <ControlPanel />
              </Suspense>
            </SideBar>
          )}

          <Suspense>
            <TaskInitializer />
          </Suspense>
        </ErrorBoundary>
      </Main>

      <ErrorBoundary fallback={<></>}>
        <Suspense>
          <FindingLabelPanel />
        </Suspense>
      </ErrorBoundary>
      <Suspense>
        <CreateIssuePanel />
      </Suspense>
      <Suspense>
        <ErrorBoundary fallback={<></>}>
          <IssueThreadPanel />
        </ErrorBoundary>
      </Suspense>
    </Container>
  );
}

const Container = styled('div')`
  width: 100vw;
  height: 100%;
  display: flex;
`;

const Main = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Content = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AsideWrapper = styled('div')<{ $isAsideVisible: boolean }>(
  ({ $isAsideVisible }) => `
  overflow: ${$isAsideVisible ? 'visible' : 'hidden'};
  width: ${$isAsideVisible ? 'var(--ctl-aside-width)' : 0};
  transition: width 0.15s ease;
`
);
