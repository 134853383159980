import { useState, useEffect } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useRecoilState, useRecoilValue } from 'recoil';

import useAlert from 'src/hooks/useAlert';
import { jobState } from 'src/states/job';
import jobIdList from 'src/states/jobIdList';
import { projectState } from 'src/states/project';

enum IdleState {
  ACTIVE = 'Active',
  IDLE = 'Idle',
  PROMPTED = 'Prompted',
}

type UseTimeTrackerReturn = {
  timeSpent: number;
  idleState: IdleState;
};

export const useTimeTracker = (): UseTimeTrackerReturn => {
  const [timeSpent, setTimeSpent] = useRecoilState(jobState.timeSpent);
  const isConfirmedProject = useRecoilValue(projectState.isConfirmed);
  const currentJobId = useRecoilValue(jobIdList.currentJobId);
  const isAnnotating = useRecoilValue(jobState.isAnnotating);
  const [idleState, setIdleState] = useState<IdleState>(IdleState.ACTIVE);
  const { open: openAlert, close: closeAlert } = useAlert();

  const onActive = () => {
    setIdleState(IdleState.ACTIVE);
    closeAlert();
  };

  const onPrompt = async () => {
    if (isConfirmedProject) return;
    setIdleState(IdleState.PROMPTED);

    openAlert({
      message: 'Time tracking is paused. Resume annotating to continue.',
      type: 'info',
    });
  };

  const { reset } = useIdleTimer({
    onIdle: () => !isConfirmedProject && setIdleState(IdleState.IDLE),
    onActive,
    onPrompt,
    startOnMount: false,
    timeout: window.Cypress ? 6_000 : 60_000,
    promptBeforeIdle: 200,
    throttle: 1000,
    eventsThrottle: 1000,
  });

  // Reset `timeSpent` when the user switches or saves the current job.
  useEffect(() => {
    if (currentJobId && !isAnnotating) {
      reset();
      setTimeSpent(0);
    }
  }, [currentJobId, isAnnotating, reset, setTimeSpent]);

  // Increment time spent on the current job.
  useEffect(() => {
    const interval = setInterval(() => {
      if (idleState === IdleState.ACTIVE) {
        setTimeSpent(prev => prev + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [idleState, setTimeSpent]);

  return { timeSpent, idleState };
};
