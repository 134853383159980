import { MouseEventHandler, memo, useState } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';

import AsideItem from 'src/components/aside/AsideItem';
import { jobState } from 'src/states/job';

import CollapsedControls from './CollapsedControls';
import ExpandedControls from './ExpandedControls';

const ControlPanel = (): JSX.Element => {
  const jobLoadable = useRecoilValueLoadable(jobState.current);
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleClickContainer: MouseEventHandler<HTMLButtonElement> = event => {
    setExpanded(prev => !prev);
  };

  const iconButton = (
    <Tooltip title="Toggle Listing Style" placement="top-end">
      <ButtonBase onClick={handleClickContainer}>
        {expanded ? (
          <ViewList fontSize="small" />
        ) : (
          <ViewModule fontSize="small" />
        )}
      </ButtonBase>
    </Tooltip>
  );

  return (
    <AsideItem label="Control" iconButtons={[iconButton]}>
      {expanded ? (
        <ExpandedControls disabled={jobLoadable.state === 'loading'} />
      ) : (
        <CollapsedControls disabled={jobLoadable.state === 'loading'} />
      )}
    </AsideItem>
  );
};

export default memo(ControlPanel);
