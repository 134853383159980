import { useState, MouseEvent, memo } from 'react';

import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';

import ModeIcon from '@mui/icons-material/Mode';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import EditableIssueItem from 'src/components/Issue/EditableIssueItem';
import AsideItem from 'src/components/aside/AsideItem';
import controlState from 'src/states/control';
import issuesState from 'src/states/issues';
import { projectState } from 'src/states/project';

import IssueItemFooter from './IssueItemFooter';

const statusMenu = ['All', 'Open', 'Closed'] as const;
type Status = (typeof statusMenu)[number];

const IssuePanel = (): JSX.Element => {
  const [status, setStatus] = useState<Status>('All');
  const [control, setControl] = useRecoilState(controlState.current);

  const isReviewer = useRecoilValue(projectState.isReviewer);
  const issuesLoadable = useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE(
    issuesState.current
  );
  const items =
    issuesLoadable.state === 'hasValue' ? issuesLoadable.contents : [];

  const [activatedId, setActivatedId] = useRecoilState(issuesState.activatedId);
  const setActivatedUpdateId = useSetRecoilState(issuesState.activatedUpdateId);

  const handleClickItem = (issueId?: string, updateId?: string) => () => {
    setActivatedId(prev => (prev === issueId ? undefined : issueId));
    setActivatedUpdateId(prev => (prev === updateId ? undefined : updateId));
  };

  const handleChangeStatus = (
    event: MouseEvent<HTMLElement>,
    value: Status
  ) => {
    setStatus(value);
  };

  const parsedItems = items.filter(issue => {
    switch (status) {
      case 'All':
        return true;
      case 'Open':
        return !issue.closed;
      case 'Closed':
        return issue.closed;
      default:
        return true;
    }
  });

  const handleClickCreateIssue = () => {
    setControl(prev => (prev === 'issuePoint' ? 'none' : 'issuePoint'));
  };

  return (
    <AsideItem label="Issues" isCollapsible>
      <ButtonWrap>
        {isReviewer && (
          <Tooltip title="Create a new issue">
            <span>
              <CreateIssueButton
                fullWidth
                color="primary"
                onChange={handleClickCreateIssue}
                value="issueMode"
                selected={control === 'issuePoint'}
                size="small"
              >
                <ModeIcon fontSize="small" />
              </CreateIssueButton>
            </span>
          </Tooltip>
        )}
        <ToggleButtonGroup
          fullWidth
          size="small"
          value={status}
          exclusive
          onChange={handleChangeStatus}
        >
          {statusMenu.map(status => (
            <StyledToggleButton value={status} key={status}>
              <ToggleButtonIconWrap>{status}</ToggleButtonIconWrap>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      </ButtonWrap>
      <IssueList>
        {parsedItems.map(
          ({ id, userName, userId, updates, createdAt, idx, closed }) => {
            const initialUpdate = updates.find(
              ({ type }) => type === 'INITIAL'
            );
            const restUpdates = updates.filter(
              ({ type }) => type !== 'INITIAL'
            );

            return initialUpdate ? (
              <IssueItemWrap key={id} $dimmed={!!closed}>
                <EditableIssueItem
                  type={initialUpdate.type}
                  style={{
                    borderRadius: 'var(--ctl-border-radius)',
                  }}
                  index={idx + 1}
                  userName={userName}
                  isReviewer={isReviewer}
                  text={initialUpdate.text || ''}
                  createdAt={createdAt}
                  issueId={id}
                  updateId={initialUpdate.id || ''}
                  userId={userId}
                  isFirstUpdate
                  closed={closed}
                  dimmed={closed}
                  maxRowsOnContent={4}
                  footerElement={
                    <IssueItemFooter
                      updates={restUpdates}
                      createdAt={createdAt}
                    />
                  }
                  onClick={handleClickItem(id, initialUpdate?.id)}
                  activated={activatedId === id}
                />
              </IssueItemWrap>
            ) : null;
          }
        )}
      </IssueList>
    </AsideItem>
  );
};

export default memo(IssuePanel);

const CreateIssueButton = styled(ToggleButton)`
  width: auto;
  min-width: auto;
`;

const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  padding: 0;
`;

const ToggleButtonIconWrap = styled('div')`
  flex: 1;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IssueList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const IssueItemWrap = styled('div')<{ $dimmed: boolean }>(
  ({ $dimmed }) => `
  border-radius: var(--ctl-border-radius);
  background-color: rgba(
    255,
    255,
    255,
    ${$dimmed ? '.05' : '.1'}
  );
`
);

const ButtonWrap = styled('div')`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;
