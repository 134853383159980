import { memo } from 'react';

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import AsideItem from 'src/components/aside/AsideItem';
import { jobState } from 'src/states/job';

function ReportPanel(): JSX.Element {
  const { report } = useRecoilValue(jobState.current);

  return (
    <AsideItem label="Report" isCollapsible>
      <Report>{report}</Report>
    </AsideItem>
  );
}

export default memo(ReportPanel);

const Report = styled('div')`
  white-space: pre-line;
`;
