import { useCallback, useRef, useState } from 'react';

import { Offset } from 'src/interfaces';
import { getOffsetFromFinding } from 'src/utils/panel';

interface UseDraggablePanelPositionReturn {
  containerRef: React.RefObject<HTMLDivElement>;
  offset: Offset | undefined;
  setOffset: (offset: Offset | undefined) => void;
  setOffsetWhenRefElementIsVisible: (
    refElementIndex: number | undefined,
    isNewIssue?: boolean
  ) => void;
}

const useDraggablePanelPosition = (): UseDraggablePanelPositionReturn => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState<Offset | undefined>(undefined);

  const findElement = useCallback(
    (refElementIndex: number | undefined, isNewIssue?: boolean) => {
      return document.querySelector(
        `svg > [data-id="${refElementIndex}"]${isNewIssue ? '' : '[data-focused="true"]'}`
      );
    },
    []
  );

  const setOffsetWhenRefElementIsVisible = useCallback(
    (refElementIndex: number | undefined, isNewIssue?: boolean) => {
      const checkExist = () => {
        const currentTime = new Date().getTime();
        const endTime = currentTime + 5 * 1000;

        const check = () => {
          const element = findElement(refElementIndex, isNewIssue);
          if (element) {
            setOffset(
              getOffsetFromFinding(
                containerRef.current,
                element.getBoundingClientRect()
              )
            );
            return;
          }
          if (endTime < new Date().getTime()) {
            return;
          }
          window.requestAnimationFrame(check);
        };

        window.requestAnimationFrame(check);
      };
      checkExist();
    },
    [findElement]
  );

  return {
    containerRef,
    offset,
    setOffset,
    setOffsetWhenRefElementIsVisible,
  };
};

export default useDraggablePanelPosition;
