import { selector, waitForAll } from 'recoil';

import { ClientError, ClientErrorCode } from 'src/http/client-error';
import {
  getAnnotatorInitialJobId,
  getReviewerInitialJobId,
  type AnnotatorJobSchema,
  type ReviewerAssociateJobSchema,
} from 'src/services/initialJobId';

import authState from './auth';
import jobIdList from './jobIdList';
import { projectState } from './project';

const annotator = selector<string | null>({
  key: 'initialJobId/annotator',
  get: async ({ get }) => {
    const { id: projectId, isReviewer, annotators } = get(projectState.current);
    const me = get(authState.signedMe);
    const isCPC = get(projectState.isCPC);
    const [annotatorJob] = annotators.filter(
      annotator => annotator.id === me.user.id
    );

    if (isReviewer || !me.user.id || !projectId || !annotatorJob) {
      return null;
    }
    return getAnnotatorInitialJobId({
      projectId,
      annotatorJob: annotatorJob as AnnotatorJobSchema,
      isCPC,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const reviewer = selector<string | null>({
  key: 'initialJobId/reviewer',
  get: async ({ get }) => {
    const me = get(authState.signedMe);
    const current = get(projectState.current);
    const {
      project: { id: projectId, isReviewer, associates },
      currentAnnotatorId,
    } = get(
      waitForAll({
        project: projectState.current,
        currentAnnotatorId: projectState.currentAnnotatorId,
      })
    );
    const isCPC = get(projectState.isCPC);

    const associateId = !!currentAnnotatorId
      ? currentAnnotatorId
      : associates[0]?.id;

    // Reviewer's associates
    const reviewerAssociates = current.reviewers.filter(
      reviewer => reviewer.id === me.user.id
    );
    // List all reviewerAssociates job list
    const [reviewerAssociatesJobList] = reviewerAssociates.map(
      ras => ras.associates
    );

    if (
      !isReviewer ||
      !me.user.id ||
      !projectId ||
      !reviewerAssociatesJobList
    ) {
      return null;
    }

    // Find current reviewer's associate job
    const reviewerAssociateJob = reviewerAssociatesJobList.find(
      associate => associate.id === associateId
    );

    if (!associateId || !reviewerAssociatesJobList || !reviewerAssociateJob) {
      throw new ClientError({
        code: ClientErrorCode.INVALID_PROJECT,
        message: 'This reviewer has no assigned associator.',
      });
    }

    if (reviewerAssociateJob.currentJobId === null) {
      const jobListForAssociate = get(jobIdList.current);
      const doesAssociateHaveJobs = jobListForAssociate.length > 0;
      throw new ClientError({
        code: doesAssociateHaveJobs
          ? ClientErrorCode.REVIEWER_ASSOCIATE_HAS_NO_RECENTLY_ANNOTATED_JOBS
          : ClientErrorCode.REVIEWER_ASSOCIATE_HAS_NO_JOBS,
      });
    }

    return getReviewerInitialJobId({
      projectId,
      reviewerAssociateJob: reviewerAssociateJob as ReviewerAssociateJobSchema,
      associateId,
      isCPC,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

const initialJobIdState = {
  annotator,
  reviewer,
};

export default initialJobIdState;
